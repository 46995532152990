import React, { useRef, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed } from 'powerbi-client';
import { Layout, SpinnerWheel } from '@loadsmart/miranda-react';
import { useAnalytics } from 'core/analytics/useAnalytics';

import {
  StyledPBIEmbedWrapper,
  StyledPageContainerDiv,
} from './Report.styles';

interface ReportComponentProps {
  selectedReport: {
    reportName: string;
    reportId: string;
    groupId: string;
    datasetId: string;
    rlsFilter: string;
    customerType: string;
    roleName: string;
  };
  accessToken: string | undefined;
  isReportLoading: boolean;
  isTokenLoading: boolean;
  isDefaultReport: boolean;
}

const ReportComponent: React.FC<ReportComponentProps> = ({
  selectedReport,
  accessToken,
  isReportLoading,
  isTokenLoading,
  isDefaultReport,
}) => {
  const currentReport = useRef<Report | null>(null);
  const { track } = useAnalytics();
  const lastSentEvent = useRef<Record<string, unknown> | null>(null);
  const lastPageChanged = useRef<string | null>(null);
  const isRegisteredFirstPageLoad = useRef(false);

  const trackEvent = (eventData: Record<string, unknown>) => {
    const eventName = eventData['Event Type'] as string;
    const loadedPage = eventData.loadedPage as string;

    if (eventName === 'pageChanged') {
      if (!isRegisteredFirstPageLoad.current) {
        isRegisteredFirstPageLoad.current = true;
        eventData['Event Type'] = 'DefaultPageLoad';
      }

      if (loadedPage === lastPageChanged.current) {
        return;
      }

      lastPageChanged.current = loadedPage;
    }

    if (
      lastSentEvent.current &&
      JSON.stringify(lastSentEvent.current) === JSON.stringify(eventData)
    ) {
      return;
    }

    lastSentEvent.current = eventData;

    try {
      track('PBIEmbedEvent' as any, eventData); // Ensure compatibility with track signature
    } catch (error) {
      console.error('Failed to send event to Mixpanel:');
    }
  };

  const buildEventData = (eventName: string, eventDetails: CustomEvent): Record<string, unknown> => {
    const eventData: Record<string, unknown> = {
      "Event Type": eventName,
      "Report Name": selectedReport.reportName,
      "Report Id": selectedReport.reportId,
      "Dataset Id": selectedReport.datasetId,
      "Dataset Group Id": selectedReport.groupId,
      "PBI User Id": selectedReport.rlsFilter,
      "PBI User Type": selectedReport.customerType,
      "Role Name": selectedReport.roleName,
      "Default Report": isDefaultReport,
    };

    if (eventName === 'dataSelected' && eventDetails.detail) {
      eventData.page = eventDetails.detail.page?.displayName;
      eventData.filters = eventDetails.detail.filters || [];
    }

    if (eventName === 'pageChanged' && eventDetails.detail) {
      eventData.loadedPage = eventDetails.detail.newPage?.displayName;
    }

    if (eventName === 'visualClicked' && eventDetails.detail) {
      eventData.page = eventDetails.detail.page?.displayName;
      eventData.filters = eventDetails.detail.filters || [];
      eventData.visualType = eventDetails.detail.visual?.type;
    }

    return eventData;
  };

  const handleEvent = (eventName: string, eventDetails: CustomEvent) => {
    const eventData = buildEventData(eventName, eventDetails);
    trackEvent(eventData);
  };

  const attachListeners = (report: Report) => {
    const powerBIEvents = ['dataSelected', 'pageChanged', 'visualClicked'];

    powerBIEvents.forEach((eventName) => {
      report.on(eventName, (details) => handleEvent(eventName, details));
    });

    return () => {
      powerBIEvents.forEach((eventName) => {
        report.off(eventName); // Provide eventName explicitly
      });
    };
  };

  const onEmbeddedComponentLoad = (embeddedComponent: Embed) => {
    if (currentReport.current) {
      isRegisteredFirstPageLoad.current = false;
      lastPageChanged.current = null;
      currentReport.current.off('pageChanged'); // Clean up event listeners
      currentReport.current.off('dataSelected');
      currentReport.current.off('visualClicked');
    }

    if ('bookmarksManager' in embeddedComponent) {
      currentReport.current = embeddedComponent as Report;
      attachListeners(currentReport.current);
    } else {
      console.error('The embedded component is not a Report.');
    }
  };

  useEffect(() => {
    return () => {
      if (currentReport.current) {
        currentReport.current.off('pageChanged');
        currentReport.current.off('dataSelected');
        currentReport.current.off('visualClicked');
      }
    };
  }, [selectedReport]);

  const getFiltersFromLocalStorage = () => {
    const storedParams = localStorage.getItem('fiai_redirect_params');
    if (!storedParams) {
        return undefined;
    }

    const params: Record<string, any> = JSON.parse(storedParams);
    const filters: any[] = [];
    let index = 0;

    while (params[`filter[${index}].column`]) {
        const report: string = params.reportName;
        const table: string = params[`filter[${index}].table`];
        const column: string = params[`filter[${index}].column`];
        const operator: string = params[`filter[${index}].operator`];

        let rawValues: string = params[`filter[${index}].values`] || "";
        rawValues = rawValues.replace(/\\x1F,/g, '\x1F');

        let split_values: string[] = rawValues.split(',');
        let values: string[] = split_values.map((value) => value.replace(/%2C/g, ','));
        values = values.map((value) => value.replace(/\x1F/g, ','));

        const logicalOperator: "And" | "Or" = params[`filter[${index}].logicalOperator`] || "Or";
        const selectedReportName: string = selectedReport.reportName;

        if (report === selectedReportName && table && column && values.length > 0) {
            const conditions = values.map((value: string) => ({
                operator, 
                value
            }));

            filters.push({
                $schema: "https://powerbi.com/product/schema#advanced",
                target: { table, column },
                logicalOperator,
                conditions,
                filterType: models.FilterType.Advanced
            });
        }
        index++;
    }

    return filters.length > 0 ? filters : undefined;
  };
 
  const filters = getFiltersFromLocalStorage();
 
  return (
    <StyledPBIEmbedWrapper>
      {(isReportLoading || isTokenLoading) && (
        <StyledPageContainerDiv style={{ height: '70vh' }}>
          <Layout.Group align="center" justify="center" style={{ flex: 1 }}>
            <SpinnerWheel size="32px" />
          </Layout.Group>
        </StyledPageContainerDiv>
      )}
      {!isReportLoading && !isTokenLoading && (
        <PowerBIEmbed
          embedConfig={{
            accessToken,
            groupId: selectedReport.groupId,
            id: selectedReport.reportId,
            tokenType: models.TokenType.Embed,
            type: 'report',
            embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${selectedReport.reportId}&groupId=${selectedReport.groupId}&_=${Date.now()}`,
            filters: filters,
            settings: {
              panes: {
                pageNavigation: {
                  visible: true,
                  position: models.PageNavigationPosition.Left,
                },
              },
              localeSettings: {
                language: "en",
                formatLocale: "en-US"
            }
            },
          }}
          cssClassName="pbi-report-class"
          getEmbeddedComponent={onEmbeddedComponentLoad}
        />
      )}
    </StyledPBIEmbedWrapper>
  );
};

export default ReportComponent;
